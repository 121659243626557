import { createStore } from 'vuex'

export default createStore({
  state: {
    sessionUnique: localStorage.getItem('vsu') || null,
    user: null,
    notifications: [],
    menu: [{
        label: 'Home',
        items: [{
            label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
        }]
    },
    {
        label: 'Orders', icon: 'pi pi-fw pi-sitemap',
        items: [
            {label: 'New Order', icon: 'pi pi-fw pi-plus', to: '/neworder'},
            {label: 'Order List', icon: 'pi pi-fw pi-table', to: '/orderlist'},
        ]
    }]
  },
  getters: {
  },
  mutations: {
    login (state, payload) {
      state.sessionUnique = payload.unique
      localStorage.setItem('vsu', payload.unique)
      state.user = payload.user
    },
    logout (state) {
      state.sessionUnique = null
      localStorage.removeItem('vsu')
      state.user = null
    }
  },
  actions: {
  },
  modules: {
  }
})
