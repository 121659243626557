<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <ConfirmPopup></ConfirmPopup>
        <Toast />
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img :src="'layout/images/logo-' + logoColor + '.svg'" alt="Sakai logo" class="mb-5" style="width:81px; height:60px;">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <!-- <div class="text-center mb-5">
                        <img src="layout/images/avatar.png" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome, Isabel!</div>
                        <span class="text-600 font-medium">Sign in to continue</span>
                    </div> -->
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="user1" class="block text-900 text-xl font-medium mb-2">Username</label>
                        <InputText id="user1" v-model="username" type="text" class="w-full mb-3" placeholder="Username" style="padding:1rem;" />
                
                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <Password id="password1" v-model="password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                
                        <div class="flex align-items-center justify-content-between mb-5">
                            <!-- <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">Remember me</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">Forgot password?</a> -->
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-xl" @click="signIn"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            checked: false
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {
        signIn() {
            let that = this
            this.axios.post('/login', {
                username: this.username,
                password: this.password
            // eslint-disable-next-line no-unused-vars
            }).then(function (response) {
                if(response.data.success) {
                    that.$store.commit('login', {
                        user: response.data.user,
                        unique: response.data.token
                    })
                    that.axios.post('/notifications', {
                        authToken: that.$store.state.sessionUnique
                    }).then(function (response) {
                        that.$store.state.notifications = response.data.notifications
                    })
                    if(that.$store.state.user.isadmin==='1') {
                        that.$store.state.menu = [{
                            label: 'Home',
                            items: [{
                                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
                            }]
                        },
                        {
                            label: 'Users', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                {label: 'New User', icon: 'pi pi-fw pi-plus', to: '/newuser'},
                                {label: 'User List', icon: 'pi pi-fw pi-table', to: '/userlist'},
                            ]
                        },
                        {
                            label: 'Orders', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                {label: 'New Order', icon: 'pi pi-fw pi-plus', to: '/neworder'},
                                {label: 'Order List', icon: 'pi pi-fw pi-table', to: '/orderlist'},
                            ]
                        }]
                    } else {
                        that.$store.state.menu = [{
                            label: 'Home',
                            items: [{
                                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
                            }]
                        },
                        {
                            label: 'Orders', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                {label: 'New Order', icon: 'pi pi-fw pi-plus', to: '/neworder'},
                                {label: 'Order List', icon: 'pi pi-fw pi-table', to: '/orderlist'},
                            ]
                        }]
                    }
                    that.$router.push('/')
                } else {
                    that.$toast.add({severity:'error', summary:'Error', detail:'Please check if username or password is correct.', life: 3000});
                }
                
            });
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>