import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/newuser',
        name: 'newuser',
        component: () => import('./pages/NewUser.vue')
    },
    {
        path: '/userlist',
        name: 'userlist',
        component: () => import('./pages/UserList.vue')
    },
    {
        path: '/neworder',
        name: 'neworder',
        component: () => import('./pages/NewOrder.vue')
    },
    {
        path: '/orderlist',
        name: 'orderlist',
        component: () => import('./pages/OrderList.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
