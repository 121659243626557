<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>UE</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button p-overlay-badge" @click="$refs.op.toggle($event)">
					<i class="pi pi-bell"></i>
					<Badge :value="$store.state.notifications.length" style="display: inline-block !important; font-size: 0.75rem; font-weight: 700; min-width: 1.5rem; height: 1.5rem; line-height: 1.5rem; top: 0.5rem; right: 0.5rem;"></Badge>
				</button>
				<OverlayPanel ref="op" appendTo="body" :showCloseIcon="false">
					<ul class="p-0 mx-0 mt-0 mb-4 list-none" v-if="$store.state.notifications.length > 0">
						<li class="flex align-items-center py-2 border-bottom-1 surface-border" v-for="notice in $store.state.notifications" :key="notice.notice_id" @click="noticeClicked($event, notice.notice_id)">
							<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
								<i class="pi pi-bell text-xl text-blue-500"></i>
							</div>
							<span class="line-height-3">
								{{ notice.notice_content }}
							</span>
						</li>
					</ul>
					<div class="px-5 py-3" v-else>
						Nothing to see here at the moment!
					</div>
				</OverlayPanel>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="logout">
					<i class="pi pi-power-off"></i>
					<span>Logout</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		logout() {
			this.$store.commit('logout')
			this.$router.push('/login')
		},
		noticeClicked(event, nid) {
			let that = this
			this.axios.patch('/notifications', {
				id: nid,
				authToken: this.$store.state.sessionUnique
			}).then(function (response) {
				that.$refs.op.toggle()
				that.$store.state.notifications = response.data.notifications
				that.$router.push('/orderlist')
			});
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>